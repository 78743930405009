.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Global */

body {
  background: #141414;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

@keyframes rainbow {
  0% {color: #a61d24;}
  9% {color: #aa3e19;}
  18% {color: #aa6215;}
  27% {color: #aa7714;}
  36% {color: #aa9514;}
  45% {color: #6f9412;}
  54% {color: #3c8618;}
  63% {color: #138585;}
  72% {color: #1554ad;} 
  81% {color: #263ea0;}
  90% {color: #51258f;}
  100% {color: #a02669;}
}

.rainbow-text:hover {
  animation: rainbow 2s linear infinite;
  cursor: none;
}


